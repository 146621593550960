import React from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useDIContext } from '@/Framework/DI/DIContext';
import { i18nInstanceSymbol } from '@/Framework/DI/Providers/i18nInstanceSymbol';
import { Select } from '@dealroadshow/uikit/core/components/Select';
import {
  SelectOptionWithCountryFlag,
  SelectSingleValueWithCountryFlag,
} from '@dealroadshow/uikit/core/components/Select/components';
import styles from './languageSwitcher.scss';

const LanguageSwitcher = ({ className, languages }) => {
  const { container } = useDIContext();
  const i18nInstance = container.get(i18nInstanceSymbol);
  const { replace, asPath } = useRouter();

  const onChangeHandler = async (country) => {
    await i18nInstance.changeLanguage(country.value);
    await replace(asPath, asPath, { locale: country.value });
  };

  return (
    <Select
      options={ languages }
      className={ cn(styles.switcher, className) }
      classNamePrefix="languageSwitcher"
      components={ {
        Option: SelectOptionWithCountryFlag,
        SingleValue: SelectSingleValueWithCountryFlag,
      } }
      value={ i18nInstance.language }
      onChange={ onChangeHandler }
      backspaceRemovesValue={ false }
      isSearchable={ false }
      isClearable={ false }
      dataTest="languageSwitcher"
    />
  );
};

export default LanguageSwitcher;
