import { useTranslation as useI18nTraslation } from 'react-i18next';
import { useDIContext } from '@/Framework/DI/DIContext';
import { i18nInstanceSymbol } from '@/Framework/DI/Providers/i18nInstanceSymbol';
import { addResources } from '@/Framework/i18n/helpers/addResources';
import { Resources } from '../types';

function useTranslation<Lang extends string> (namespace: string, resources: Resources<Lang>) {
  const { container } = useDIContext();
  const i18nInstance = container.get(i18nInstanceSymbol);
  addResources(i18nInstance, namespace, resources);
  const { t } = useI18nTraslation(namespace, { i18n: i18nInstance });

  return { t, i18nInstance };
}

export default useTranslation;
