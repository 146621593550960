import { i18n } from 'i18next';
import { Resources } from '../types';

export function addResources<Lang extends string>(
  i18n: i18n,
  namespace: string,
  resources: Resources<Lang>,
) {
  Object.entries(resources).forEach(([lang, resource]) => {
    if (!i18n.hasResourceBundle(lang, namespace)) {
      i18n.addResourceBundle(lang, namespace, resource);
    }
  });
}
