import { useEffect, useState, useMemo, PropsWithChildren } from 'react';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useRouter } from 'next/router';
import Favicon from '@/dealroadshow/ui/common/Favicon';
import IconLock from '@dealroadshow/uikit/core/components/Icon/IconLock';
import IconUserLock from '@dealroadshow/uikit/core/components/Icon/IconUserLock';
import CookiesBanner from '@/Framework/UI/Molecules/CookiesBanner';
import Header from '@/dataroom/ui/components/Landing/Legacy/Sections/Header';
import LanguageSwitcher from '@/Framework/i18n/LanguageSwitcher';
import Footer from '../Footer';
import { useCustomizationContext } from '@/dealroadshow/application/Viewer/CustomizationContext';
import useTranslation from '@/Framework/i18n/hooks/useTranslation';

import logo from '../../../assets/dealroadshowLandingLogo.svg';

import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import config from '@/dealroadshow/application/config/config';
import { LANGUAGES } from '@/dealroadshow/application/config/lang';
import DEALROADSHOW_HEADER_NAMESPACE from './locales/constant';
import en from './locales/en.json';
import ja from './locales/ja.json';

import { isScreenXs } from '@dealroadshow/uikit/core/styles/screen/screen';
import headerStyles from '@/dataroom/ui/components/Landing/Legacy/Sections/Header/header.scss';
import stickyFooterStyles from '@/Framework/UI/Organisms/StickyFooter/stickyFooter.scss';
import styles from './layout.scss';

const LANGUAGES_OPTIONS = [
    { value: LANGUAGES.EN, label: 'ENG', countryCode: 'US' },
    { value: LANGUAGES.JA, label: '日本語', countryCode: 'JP' },
  ];

const Layout = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const entryCode = router.query.entryCode as string;
  const [isMobile, setIsMobile] = useState(isScreenXs());
  const { t } = useTranslation(DEALROADSHOW_HEADER_NAMESPACE, { en, ja });

  const {
    colorSettings = {},
    getViewerCustomSettings,
    isPending,
    isCustomizationDisclaimerEnabled,
  } = useCustomizationContext();
  const isInvestorLogin = router.pathname === '/' || router.pathname.includes('/login/investor');

  const headerMenu = useMemo(() => ([
    {
      key: 'institutional',
      title: isInvestorLogin ? t('headerInstitutional') : 'Institutional',
      path: '/',
      isActive: () => isInvestorLogin,
    },
    {
      key: 'retail',
      title: isInvestorLogin ? t('headerRetail') : 'Retail Roadshows',
      path: '/retail-roadshows',
    },
  ]), [router.pathname, t]);

  useEffect(() => {
    if (entryCode) {
      getViewerCustomSettings(entryCode);
    }
  }, []);

  const loginLinkCls = cn(
    headerStyles.menuLink,
    headerStyles.menuIconLink,
    headerStyles.menuLoginButton,
    headerStyles.tenantDealroadshow,
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(isScreenXs());
    window.addEventListener('resize', throttle(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO SSR: refactor this to enable SSR fro Header
  const shouldRenderHeader = !entryCode || (!isPending && !colorSettings?.viewerDisclaimerCustomizationEnabled);

  return (
    <>
      <Favicon />
      { shouldRenderHeader && (
        <Header
          isNext
          logo={ logo }
          menu={ headerMenu }
          tenant={ config.code }
          loginButton={ () => (
            <div className={ styles.loginButtonContainer }>
              { isInvestorLogin && (
                <LanguageSwitcher
                  className={ headerStyles.menuLoginButton }
                  languages={ LANGUAGES_OPTIONS }
                />
              ) }
              <a
                href={ drsUrl.getLoginUrl() }
                className={ loginLinkCls }
                data-test="headerLogIn"
              >
                { isMobile ? <IconUserLock className={ headerStyles.menuLoginIcon } /> :
                <IconLock className={ headerStyles.menuLoginIcon } /> }
                { isInvestorLogin ? t('headerDealManager') : 'Deal Manager' }
              </a>
            </div>
          ) }
        />
      ) }
      <div
        className={
        cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter, {
          [styles.pageContainerCustom]: isCustomizationDisclaimerEnabled && entryCode,
        })
      }
      >
        { children }
      </div>
      <Footer />
      <CookiesBanner />
    </>
  );
};

export default Layout;
